<template>
  <!-- <div id="video-vr" ref="videoWrapper"> -->
  <div id="video-item" ref="close" @click="handleJump">关闭</div>
  <!-- </div> -->
</template>
<script>
var playerObj
/*播放器参数配置*/
var params = {}
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
export default defineComponent({
  setup() {
    const route = useRoute()
    const router = useRouter()
    const close = ref(null)
    const path = ref('')
    // const videoWrapper = ref(null)
    const videoWrapper = document.getElementById('video-vr')
    onMounted(() => {
      if (route.query.v == 1) {
        router.push('/video')
        // location.reload()
      }
      params = {
        container: document.getElementById('video-vr'),
        name: 'SceneViewer',
        isGyro: true,
        scenesArr: [
          //todo:注意修改视频路径，需要保证播放页面与视频属于同一域名下
          {
            sceneId: 'v1',
            sceneName: '赛车',
            sceneFilePath: path.value,
            sceneType: 'Video',
          },
        ],
        //播放器不支持全景播放回调
        errorCallBack: function (e) {
          console.log('错误状态：' + e)
        },
        //浏览器不支持全屏回调
        fsCallBack: function (status, playObj) {
          alert('浏览器不支持全屏！')
        },
        initOverCallBack: function () {
          /*将播放器对象赋值给playerObj*/
          playerObj = this
        },
      }
      // console.log('route :>> ', route.query.fromPath);

      //   videoWrapper.style.display = "block";
      //   window.scrollTo(0, 0);
      initLoad(params)
    })
    const handleJump = () => {
      videoWrapper.innerHTML = ''
      videoWrapper.style.zIndex = -1
      router.go(-1)
      // location.reload()
    }
    return {
      close,
      handleJump,
    }
  },
})
</script>

<style>
#video-vr {
  position: absolute;
  width: 100vw;
  height: 100vh;
  width: 100%;
  height: 100%;
  top: 0;
  /* left: 50%;
      top: 50%;
      transform: translateX(-50%); */
  bottom: 0;
  overflow: hidden;
  /* display: none; */
}

#video-item {
  position: absolute;
  width: 70px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 10px;
  cursor: pointer;
  /* background: url(./image/guanbi.png) no-repeat;
      background-size: 100% 100%; */
  background: #52b470;
  color: #fff;
  z-index: 999;
  right: 10px;
  top: 10px;
}
</style>
